import { WebSocketService } from '@curvebeamai/web-connect-service';
import { AWSCredentialsViewModel, SiApiResponseViewModel } from '../models';

export type GetExpiringAWSCredentialsParams = {
  fetchExpiringCredentialsURI: string;
  setInitError: React.Dispatch<React.SetStateAction<string>>;
};

// Avoid async/await syntax because we intend for this funciton to be used in a useEffect hook
export const getExpiringAWSCredentials = async function getExpiringAWSCredentials(
  params: GetExpiringAWSCredentialsParams
): Promise<AWSCredentialsViewModel | null> {
  const { fetchExpiringCredentialsURI, setInitError } = params;
  const webSocketServiceInstance = WebSocketService.getInstance();
  const accessToken = webSocketServiceInstance.getAccessToken();
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const resp = await fetch(fetchExpiringCredentialsURI, requestOptions);

  if (resp.status !== 200 || resp.ok !== true) {
    console.error('Failed to fetch expiring credentials');
    setInitError('Failed to fetch expiring credentials');
    return null;
  }
  const siApiResponse = new SiApiResponseViewModel().fromData(await resp?.json());
  const credentials = new AWSCredentialsViewModel().fromData(siApiResponse?.data);

  if (!credentials.AccessKeyId || !credentials.SecretAccessKey || !credentials.SessionToken) {
    console.error('Failed to fetch expiring credentials');
    setInitError('Failed to fetch expiring credentials');
    return null;
  }

  return credentials;
};
