import axios, { AxiosError } from 'axios';
import { BaseApiResponse } from './dtos/base-api-response.dto';
import { GenerateRoiProjectionRequest } from './dtos/result.dto';
import { ScanDetail } from '../models/scan.interface';
import { BaseScanRequest } from './dtos/scan.dto';

// - Bmd:
export class BmdApiService {
  private baseUrl = 'http://localhost:3002/api/bmd';

  private static instance: BmdApiService;

  static getInstance(): BmdApiService {
    if (!BmdApiService.instance) {
      BmdApiService.instance = new BmdApiService();
    }

    return BmdApiService.instance;
  }

  setPipelineCredential(url: string) {
    this.baseUrl = url;
  }

  private buildUrl(path: string) {
    return `${this.baseUrl}/${path}`;
  }

  async getScanDetails(request: BaseScanRequest): Promise<ScanDetail> {
    const response = await axios
      .post<BaseApiResponse<ScanDetail>>(
        this.buildUrl('getScanDetails'),
        request
      )
      .catch(this.handleErrorResponse);

    return response.data.data;
  }

  async generateRoiProjection(
    request: GenerateRoiProjectionRequest
  ): Promise<ScanDetail> {
    const response = await axios
      .post<BaseApiResponse<ScanDetail>>(
        this.buildUrl('generateRoiProjection'),
        request
      )
      .catch(this.handleErrorResponse);

    return response.data.data;
  }

  private handleErrorResponse(error: AxiosError): never {
    const { response } = error;

    if (!response) {
      throw new Error(
        'Cannot connect to server, please refresh the page and try again'
      );
    }

    throw new Error(response.data.message);
  }
}
