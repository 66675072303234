export class SiApiResponseViewModel {
  code: string;
  message: string;
  data: any;

  fromData(data: any): SiApiResponseViewModel {
    this.code = data?.code;
    this.message = data?.message;
    this.data = data?.data;
    return this;
  }
}
