// External
import i18n from '@ohif/i18n';
import {
  ExtensionManager,
  CommandsManager,
  HotkeysManager,
  ServiceProvidersManager,
} from '@ohif/core';
import {
  CineProvider,
  DialogProvider,
  Modal,
  ModalProvider,
  SnackbarProvider,
  ThemeWrapper,
  UserAuthenticationProvider,
  ViewportDialogProvider,
  ViewportGridProvider,
  ToolboxProvider,
} from '@ohif/ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Compose from './routes/Mode/Compose';
// Viewer Project
// TODO: Should this influence study list?
import { AppConfigProvider } from '@state';
import appInit from './appInit.js';
import createRoutes from './routes';
import {
  getExpiringAWSCredentials,
  appInitErrorResolver,
  injectAWSConfig,
  validateSession,
} from './utils';
import OpenIdConnectRoutes from './utils/OpenIdConnectRoutes';
import { AppConfigViewModel, SessionConnectionStateEnum } from './models';

let commandsManager: CommandsManager,
  extensionManager: ExtensionManager,
  servicesManager: AppTypes.ServicesManager,
  serviceProvidersManager: ServiceProvidersManager,
  hotkeysManager: HotkeysManager;

function App({ config, defaultExtensions, defaultModes }) {
  const [init, setInit] = useState(null);
  const [sessionState, setSessionState] = useState(SessionConnectionStateEnum.PENDING);
  const [initError, setInitError] = useState('');

  const configModel = new AppConfigViewModel().fromData(config);

  useEffect(() => {
    // [Curvebeam] - Soft guard to restrict users to opening DICOM Viewer from DMP-associated websites
    if (
      process.env.NODE_ENV !== 'development' &&
      configModel?.referrerWhitelist.includes(document.referrer) === false
    ) {
      setInitError(
        'Please open the DICOM Viewer from the CurvebeamAI Data Management Platform (DMP)'
      );
      return;
    }

    // [Curvebeam] Define entire app initialization process in the run() function
    const run = async () => {
      try {
        // [Curvebeam] If DMP access token is not validated, skip regular initization and validate DMP access token first

        // If the session state is disconnected, return since web socket has disconnected
        if (sessionState === SessionConnectionStateEnum.DISCONNECTED) {
          return;
        }

        // If session state is Pending, try to connect
        if (sessionState === SessionConnectionStateEnum.PENDING) {
          // [Curvebeam] Validate access token from DMP
          // [Curvebeam] Place the highest priority error at the bottom - otherwise will be overwritten by earlier 'setInitError' invocations
          validateSession({
            validateDMPAccessTokenEndpoint: configModel?.validateAccessTokenEndpoint,
            clientId: configModel?.clientId,
            webSocketURL: configModel?.webSocketURL,
            setInitError: setInitError,
            setSessionState: setSessionState,
          });

          return;
        }

        if (sessionState === SessionConnectionStateEnum.CONNECTED) {
          // [Curvebeam] Beyond this point requires a valid DMP access token in local storage
          // [Curvebeam] Get expiring AWS credentials to authenticate AWS HealthImaging requests
          const expiringAWSCredentials = await getExpiringAWSCredentials({
            fetchExpiringCredentialsURI: configModel?.fetchExpiringCredentialsURI,
            setInitError: setInitError,
          });

          // [Curvebeam] Dynamically inject AWS config
          injectAWSConfig({
            config: configModel,
            expiringAWSCredentials: expiringAWSCredentials,
            setInitError: setInitError,
          });

          const appInitResult = await appInit(config, defaultExtensions, defaultModes);
          setInit(appInitResult);
        }
      } catch (e) {
        console.error(e.message);
        setInitError(e.message);
      }
    };

    run();
    // [Curvebeam] We want app to retry initialization once DMP access token is validated
  }, [sessionState]);

  // [Curvebeam] Add global unhandled Promise rejection handler, prompt user to check console and network tab for more details.
  // [Curvebeam] Intention is to avoid the forever loading screen during network error.
  window.addEventListener('unhandledrejection', event => {
    setInitError(
      `ERROR: ${event?.reason?.message}. Please check browser console and network tab for more details`
    );
  });

  // [Curvebeam] Handle app initialization errors
  const appInitErrorPage = appInitErrorResolver(init, sessionState, initError);
  // [Curvebeam] Any error => stop regular app flow
  if (appInitErrorPage !== null) {
    return appInitErrorPage;
  }

  // Set above for named export
  commandsManager = init.commandsManager;
  extensionManager = init.extensionManager;
  servicesManager = init.servicesManager;
  serviceProvidersManager = init.serviceProvidersManager;
  hotkeysManager = init.hotkeysManager;

  // Set appConfig
  const appConfigState = init.appConfig;
  const { routerBasename, modes, dataSources, oidc, showStudyList } = appConfigState;

  // get the maximum 3D texture size
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl2');

  const max3DTextureSize = gl.getParameter(gl.MAX_3D_TEXTURE_SIZE);
  appConfigState.max3DTextureSize = max3DTextureSize;

  const {
    uiDialogService,
    uiModalService,
    uiNotificationService,
    uiViewportDialogService,
    viewportGridService,
    cineService,
    userAuthenticationService,
    customizationService,
    bmdService,
  } = servicesManager.services;

  bmdService.bmdApiService.setPipelineCredential(config?.servicePipelineUrl);

  const providers = [
    [AppConfigProvider, { value: appConfigState }],
    [UserAuthenticationProvider, { service: userAuthenticationService }],
    [I18nextProvider, { i18n }],
    [ThemeWrapper],
    [ToolboxProvider],
    [ViewportGridProvider, { service: viewportGridService }],
    [ViewportDialogProvider, { service: uiViewportDialogService }],
    [CineProvider, { service: cineService }],
    [SnackbarProvider, { service: uiNotificationService }],
    [DialogProvider, { service: uiDialogService }],
    [ModalProvider, { service: uiModalService, modal: Modal }],
  ];

  // Loop through and register each of the service providers registered with the ServiceProvidersManager.
  const providersFromManager = Object.entries(serviceProvidersManager.providers);
  if (providersFromManager.length > 0) {
    providersFromManager.forEach(([serviceName, provider]) => {
      providers.push([provider, { service: servicesManager.services[serviceName] }]);
    });
  }

  const CombinedProviders = ({ children }) => Compose({ components: providers, children });

  let authRoutes = null;

  // Should there be a generic call to init on the extension manager?
  customizationService.init(extensionManager);

  // Use config to create routes
  const appRoutes = createRoutes({
    modes,
    dataSources,
    extensionManager,
    servicesManager,
    commandsManager,
    hotkeysManager,
    routerBasename,
    showStudyList,
  });

  if (oidc) {
    authRoutes = (
      <OpenIdConnectRoutes
        oidc={oidc}
        routerBasename={routerBasename}
        userAuthenticationService={userAuthenticationService}
      />
    );
  }

  return (
    <CombinedProviders>
      <BrowserRouter basename={routerBasename}>
        {authRoutes}
        {appRoutes}
      </BrowserRouter>
    </CombinedProviders>
  );
}

App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
      oidc: PropTypes.array,
      whiteLabeling: PropTypes.object,
      extensions: PropTypes.array,
    }),
  ]).isRequired,
  /* Extensions that are "bundled" or "baked-in" to the application.
   * These would be provided at build time as part of they entry point. */
  defaultExtensions: PropTypes.array,
};

App.defaultProps = {
  config: {
    /**
     * Relative route from domain root that OHIF instance is installed at.
     * For example:
     *
     * Hosted at: https://ohif.org/where-i-host-the/viewer/
     * Value: `/where-i-host-the/viewer/`
     * */
    routerBaseName: '/',
    /**
     *
     */
    showLoadingIndicator: true,
    showStudyList: true,
    oidc: [],
    extensions: [],
  },
  defaultExtensions: [],
};

export default App;

export { commandsManager, extensionManager, servicesManager };
