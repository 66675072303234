export class AppConfigViewModel {
  dataSources: {
    friendlyName: string;
    configuration: {
      healthlake?: {
        datastoreID: string;
        endpoint: string;
        awsSecretAccessKey?: string;
        awsAccessKeyID?: string;
        awsSessionToken?: string;
      };
    };
  }[];

  // [Curvebeam] URI for validating DMP access token
  validateAccessTokenEndpoint: string;
  // [Curvebeam] ClientId for WebSocket connection
  clientId: string;
  // [Curvebeam] URI for WebSocket connection
  webSocketURL?: string;
  // [Curvebeam] URI for BMD pipeline API
  servicePipelineUrl?: string;
  // [Curvebeam] URI for AWS HealthImaging
  awsHealthImagingEndpoint?: string;
  // [Curvebeam] URI for DMP home page
  dmpHomeURL?: string;
  // [Curvebeam] List of allowed referrers for DICOM Viewer
  referrerWhitelist?: string[];
  // [Curvebeam] URI for fetching expiring AWS credentials
  fetchExpiringCredentialsURI?: string;

  fromData(data: any): AppConfigViewModel {
    this.dataSources = data?.dataSources;
    this.validateAccessTokenEndpoint = data?.validateAccessTokenEndpoint;
    this.clientId = data?.clientId;
    this.webSocketURL = data?.webSocketURL;
    this.servicePipelineUrl = data?.servicePipelineUrl;
    this.awsHealthImagingEndpoint = data?.awsHealthImagingEndpoint;
    this.dmpHomeURL = data?.dmpHomeURL;
    this.referrerWhitelist = data?.referrerWhitelist;
    this.fetchExpiringCredentialsURI = data?.fetchExpiringCredentialsURI;
    return this;
  }
}
