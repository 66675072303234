import { OrientationAxis } from '@cornerstonejs/core/dist/esm/enums';

// - Bmd:
export class BmdViewportRotation {
  viewportName: OrientationAxis;
  /**
   * Rotation in radians
   */
  rotation: number;
}
