import { Point2 } from '@cornerstonejs/core/dist/esm/types';

export class Math2d {
  static add(point1: Point2, point2: Point2): Point2 {
    return [point1[0] + point2[0], point1[1] + point2[1]];
  }

  static subtract(point1: Point2, point2: Point2): Point2 {
    return [point1[0] - point2[0], point1[1] - point2[1]];
  }

  static radianToDegree(radian: number): number {
    return (radian * 180) / Math.PI;
  }

  static degreeToRadian(degree: number): number {
    return (degree * Math.PI) / 180;
  }
}
