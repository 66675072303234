export class AWSCredentialsViewModel {
  AccessKeyId: string;
  SecretAccessKey: string;
  SessionToken: string;
  Expiration: string;

  fromData(data: any): AWSCredentialsViewModel {
    this.AccessKeyId = data?.AccessKeyId;
    this.SecretAccessKey = data?.SecretAccessKey;
    this.SessionToken = data?.SessionToken;
    this.Expiration = data?.Expiration;
    return this;
  }
}
