import ErrorPage from '../components/ErrorPage';
import { SessionConnectionStateEnum } from '../models';

export const appInitErrorResolver = function appInitErrorResolver(
  init: unknown,
  sessionState: SessionConnectionStateEnum,
  initError: string
): JSX.Element | null {
  if (initError !== '') {
    return ErrorPage(initError);
  }
  if (sessionState === SessionConnectionStateEnum.PENDING) {
    return ErrorPage('Pending DMP Access Token validation...');
  }
  if (init === null) {
    return ErrorPage('App initializing...');
  }
  return null;
};
