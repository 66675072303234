export interface RoiInfo {
  /** Width in mm (2px) */
  fnWidth: number;
  /** Height in mm (2px) */
  fnHeight: number;
  fnCentrePt: [number, number];
  // In degrees
  fnAxisAngle: number;
  shaftLine: number;
}

export interface ProjectionTableData {
  roi: string;
  bmd: number;
  tScore: number;
  zScore: number;
}

export interface PatientInfo {
  name: string;
  DOB: string;
  age: number;
  sex: 'Male' | 'Female' | 'Other';
  acquisitionDate: string;
  processingDate: string;
  organisation: string;
}

export interface ResultReport {
  createdDateTime: string;
  downloadUrl: string;
  imageUrls: {
    projectionImageUrl: string;
    femoralNeckUrl: string;
    totalHipUrl: string;
  };
  projectionTableData: ProjectionTableData[];
  patientInfo: PatientInfo;
  laterality: 'Left' | 'Right';
  interpretation: string;
}

export enum ResultStatus {
  New = 'new',
  MipProcessing = 'mip_processing',
  MipProcessed = 'mip_processed',
  Cropping = 'cropping',
  Cropped = 'cropped',
  RoiProcessing = 'roi_processing',
  RoiProcessed = 'roi_processed',
  BmdProcessing = 'bmd_processing',
  BmdProcessed = 'bmd_processed',
  Fail = 'fail',
  ErrorMipProcessing = 'error_mip_processing',
  ErrorCropping = 'error_cropping',
  ErrorRoiProcessing = 'error_roi_processing',
  ErrorBmdProcessing = 'error_bmd_processing',
  ErrorAutoRotating = 'error_auto_rotating',
  ErrorAutoCropping = 'error_auto_cropping',
}

export const ResultStatusDisplay: Record<string, string> = {
  [ResultStatus.New]: 'New',
  [ResultStatus.Cropping]: 'In Progress',
  [ResultStatus.Cropped]: 'In Progress',
  [ResultStatus.RoiProcessing]: 'In Progress',
  [ResultStatus.RoiProcessed]: 'In Progress',
  [ResultStatus.BmdProcessing]: 'In Progress',
  [ResultStatus.BmdProcessed]: 'Completed',
  [ResultStatus.ErrorCropping]: 'Error',
  [ResultStatus.ErrorRoiProcessing]: 'Error',
  [ResultStatus.ErrorBmdProcessing]: 'Error',
  [ResultStatus.MipProcessing]: 'In Progress',
  [ResultStatus.MipProcessed]: 'In Progress',
  [ResultStatus.ErrorMipProcessing]: 'Error',
  [ResultStatus.Fail]: 'Error',
  [ResultStatus.ErrorAutoRotating]: 'Error',
  [ResultStatus.ErrorAutoCropping]: 'Error',
};

export const SuccessResultStatuses = [
  ResultStatus.Cropped,
  ResultStatus.RoiProcessed,
  ResultStatus.BmdProcessed,
];

export const ErrorResultStatuses = [
  ResultStatus.ErrorCropping,
  ResultStatus.ErrorRoiProcessing,
  ResultStatus.ErrorBmdProcessing,
  ResultStatus.Fail,
  ResultStatus.ErrorAutoRotating,
  ResultStatus.ErrorAutoCropping,
];

export const InProgressResultStatuses = [
  ResultStatus.New,
  ResultStatus.MipProcessing,
  ResultStatus.MipProcessed,
  ResultStatus.Cropping,
  ResultStatus.Cropped,
  ResultStatus.RoiProcessing,
  ResultStatus.RoiProcessed,
  ResultStatus.BmdProcessing,
];

export enum Workflow {
  Automatic = 'automatic',
  Manual = 'manual',
}

export const WorkflowDisplay: Record<string, string> = {
  [Workflow.Automatic]: 'Automatic',
  [Workflow.Manual]: 'Manual',
};

export interface ResultDetail {
  organizationId: string;
  resultUID: string;
  version: string;
  createdDateTime: string;
  workflow: Workflow;
  status: ResultStatus;
  errorMessage?: string;

  // Only needed when status is cropped
  imageSetId?: string;
  datastoreId?: string;

  // Only needed when status is roi_processed
  roiInfo?: RoiInfo;
  // Final report. Set null when null available
  report?: ResultReport;
  roiProjectionUrl?: string;
}