// [Curvebeam] We want to take the URL query params, and override the DICOM image endpoint set in the AppConfig object.

import { AppConfigViewModel, AWSCredentialsViewModel } from '../models';

export type InjectAWSConfigParams = {
  config: AppConfigViewModel;
  expiringAWSCredentials: AWSCredentialsViewModel;
  setInitError: React.Dispatch<React.SetStateAction<string>>;
};

export const injectAWSConfig = function injectAWSConfig(
  params: InjectAWSConfigParams
) {
  const { config, expiringAWSCredentials, setInitError } = params;

  const URLQueryParams = new URLSearchParams(window.location.search);
  const datastore = URLQueryParams.get('datastore');
  if (datastore === null) {
    console.error('No `datastore` query param found in URL');
    setInitError('No `datastore` query param found in URL');
    return;
  }

  // Expecting .filter to create a shallow copy, so if we modify the object it should mutate the `config` parameter pass into this function
  const healthlakeDatasourceArray = config?.dataSources?.filter(
    datasource => datasource?.friendlyName === 'AWS HealthImaging'
  );

  if (healthlakeDatasourceArray.length === 0) {
    console.error(
      'No dataSource with `friendlyName`:`AWS HealthImaging` found in BASE_AWS_CURVEBEAM_CONFIG'
    );
    setInitError(
      'No dataSource with `friendlyName`:`AWS HealthImaging` found in BASE_AWS_CURVEBEAM_CONFIG'
    );
    return;
  }

  const healthlakeDatasource = healthlakeDatasourceArray[0];

  // Inject environment config into runtime config object
  healthlakeDatasource.configuration.healthlake.datastoreID = datastore;
  healthlakeDatasource.configuration.healthlake.endpoint =
    config.awsHealthImagingEndpoint;

  // [Curvebeam] Inject AWS credentials into runtime config object - 'ohif-aws-healthimaging' dependency expects AWS credentials in this location
  healthlakeDatasource.configuration.healthlake.awsAccessKeyID =
    expiringAWSCredentials?.AccessKeyId;

  healthlakeDatasource.configuration.healthlake.awsSecretAccessKey =
    expiringAWSCredentials?.SecretAccessKey;

  healthlakeDatasource.configuration.healthlake.awsSessionToken =
    expiringAWSCredentials?.SessionToken;

  return;
};
