import { getEnabledElementByIds, VolumeViewport } from '@cornerstonejs/core';
import { OrientationAxis, ViewportType, } from '@cornerstonejs/core/dist/esm/enums';
import { IStackViewport, IVolumeViewport, } from '@cornerstonejs/core/dist/esm/types';
import { getToolGroup } from '@cornerstonejs/tools/dist/esm/store/ToolGroupManager';
import { ErrorResultStatuses, InProgressResultStatuses, } from '../models/result.interface';
import { ScanDetail } from '../models/scan.interface';

// - Bmd: Some shared utilities functions
export class BmdUtils {
  static isBmd(): boolean {
    const params = new URLSearchParams(window.location.search);
    const imageSource = params.get('imageSource');
    return imageSource === 'bmd';
  }

  static getBmdParamsFromURL(): {
    organizationId: string;
    seriesUID: string;
    resultUID: string;
  } {
    const params = new URLSearchParams(window.location.search);
    const organizationId = params.get('organizationId');
    const seriesUID = params.get('seriesUID');
    const resultUID = params.get('resultUID');

    if (!organizationId || !seriesUID || !resultUID) {
      throw new Error('Missing organizationId, seriesUID or resultUID');
    }

    return {
      organizationId,
      seriesUID,
      resultUID,
    };
  }

  static isVolumeViewport(
    viewport?: IVolumeViewport | IStackViewport
  ): viewport is VolumeViewport {
    return viewport?.type === ViewportType.ORTHOGRAPHIC;
  }

  static getViewportByOrientation(
    orientation: OrientationAxis
  ): IVolumeViewport {
    const viewports = this.getVolumeViewports();
    return viewports.find(x => x.options.orientation === orientation);
  }

  static getVolumeViewports(toolGroupId = 'mpr'): IVolumeViewport[] {
    const viewports: IVolumeViewport[] = [];

    const viewportIds = getToolGroup(toolGroupId).viewportsInfo;
    if (viewportIds.length !== 3) {
      throw new Error('You need to be in MPR view');
    }

    for (const viewportId of viewportIds) {
      const enabledElement = getEnabledElementByIds(
        viewportId.viewportId,
        viewportId.renderingEngineId
      );
      if (!enabledElement) {
        throw new Error('Enabled element not found');
      }

      const { viewport } = enabledElement;
      if (!this.isVolumeViewport(viewport)) {
        throw new Error('Viewport is not volume viewport');
      }

      viewports.push(viewport);
    }

    return viewports;
  }

  static triggerRenderForAllViewport = () => {
    const viewports = this.getVolumeViewports();
    for (const viewport of viewports) {
      viewport.render();
    }
  };

  static isInProgress(scanDetail: ScanDetail) {
    return InProgressResultStatuses.includes(scanDetail.status);
  }

  static isError(scanDetail: ScanDetail) {
    return ErrorResultStatuses.includes(scanDetail.status);
  }

  static formatDate(dateString?: string) {
    if (!dateString) return 'NA';

    try {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${this.padNumber(
        date.getMonth() + 1,
        2
      )}-${this.padNumber(date.getDate(), 2)}`;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  static padNumber(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
}
