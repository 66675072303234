import React from 'react';

function ErrorPage(message = 'Error') {
  return (
    <div className="w-full h-full flex justify-center items-center text-white">
      <div>
        <h4>{message}</h4>
      </div>
    </div>
  );
}

export default ErrorPage;
