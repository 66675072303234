import {
  ConnectionOptions,
  WebSocketService,
  WebSocketServiceResponseObject,
} from '@curvebeamai/web-connect-service';
import { SessionConnectionStateEnum } from '../models';

// [Curvebeam] Use object function param to enable named function parameters
export type GetAccessTokenParams = {
  validateDMPAccessTokenEndpoint: string;
  clientId: string;
  webSocketURL: string;
  setInitError: React.Dispatch<React.SetStateAction<string>>;
  setSessionState: React.Dispatch<React.SetStateAction<string>>;
};

export const validateSession = function validateSession(
  params: GetAccessTokenParams
): Promise<void> {
  const { validateDMPAccessTokenEndpoint, clientId, webSocketURL, setInitError, setSessionState } =
    params;

  const URLQueryParams = new URLSearchParams(window.location.search);
  const session_id = URLQueryParams.get('session_id');
  if (session_id === null) {
    console.error('session_id not provided as URL query parameter');
    localStorage.clear();
    setInitError('Error: Did not provide session_id URL query parameter');
    return;
  }

  const webSocketServiceInstance = WebSocketService.getInstance();

  const webSocketConnectionOptions = new ConnectionOptions(
    webSocketURL,
    clientId,
    session_id,
    validateDMPAccessTokenEndpoint
  );

  webSocketServiceInstance.connect(webSocketConnectionOptions).then(response => {
    if (response.code === 200) {
      console.log('WebSocket connected', response);
      setSessionState(SessionConnectionStateEnum.CONNECTED);
    } else {
      console.error('WebSocket Error', response.message);
      setSessionState(SessionConnectionStateEnum.DISCONNECTED);
      setInitError(response.message);
    }
  });

  webSocketServiceInstance.connectionClosedSubscription.subscribe(
    (response: WebSocketServiceResponseObject) => {
      console.log('WebSocket disconnected', response);
      if (response.code === 500) {
        setInitError(
          'There has been an error establishing your session. Please contact CurveBeam AI support team.'
        );
      } else {
        setInitError(response.message);
      }
      setSessionState(SessionConnectionStateEnum.DISCONNECTED);
    }
  );
};

export const getAccessToken = function getAccessToken() {
  const websocketServiceInstance = WebSocketService.getInstance();
  return websocketServiceInstance.getAccessToken();
};
