import { BmdUtils, BmdViewportRotation, Types } from '@ohif/core';
import { CommandsManager } from '../../classes';
import ServicesManager from '../ServicesManager';
import { PubSubService } from '../_shared/pubSubServiceInterface';
import { BmdApiService } from './apis/bmd-api.service';
import { ScanDetail } from './models/scan.interface';

// We have some trouble for the status update in the service pipeline
// We set to a very large number for now so it doesn't auto refresh
const autoRefreshDelay = 10000000000;

// - Bmd: Central services that enables the data transmission between ReactJS UI and tools
export class BmdService extends PubSubService {
  static REGISTRATION = {
    name: 'bmdService',
    create: ({
      commandsManager,
      servicesManager,
    }: Types.Extensions.ExtensionParams): BmdService => {
      const bmdApiService = BmdApiService.getInstance();

      return new BmdService(commandsManager, servicesManager, bmdApiService);
    },
  };

  // - For BmdRotationTool
  rotationStack: BmdViewportRotation[] = [];
  initialized = false;

  // - Roi data
  organizationId: string;
  seriesUID: string;
  resultUID: string;
  scanDetail: ScanDetail;

  // - Events for BmdRotationTool to subscribe
  resetCameraRotation?: () => void;

  // - Events for React UI to subscribe
  scanDetailUpdated?: (scanDetail: ScanDetail) => void;
  showError?: (message: string) => void;
  showLoadingToast?: (message: string, durationInMS: number) => void;
  showInfo?: (message: string) => void;
  showSuccess?: (message: string) => void;
  initializingUpdated?: (initializing: boolean) => void;
  apiLoadingUpdated?: (loading: boolean) => void;

  // - Some helper properties
  initializing = false;
  apiLoading = false;

  constructor(
    private commandsManager: CommandsManager,
    private servicesManager: ServicesManager,
    public bmdApiService: BmdApiService
  ) {
    super();
  }

  setInitializing(initializing: boolean): void {
    this.initializing = initializing;
    this.initializingUpdated?.(initializing);
  }

  setApiLoading(loading: boolean): void {
    this.apiLoading = loading;
    this.apiLoadingUpdated?.(loading);
  }

  /**
   * Called once MPR view initialized
   */
  async init(): Promise<void> {
    if (this.initialized) return;

    this.setInitializing(true);
    // - Get result details from the backend
    const { organizationId, seriesUID, resultUID } =
      BmdUtils.getBmdParamsFromURL();
    this.organizationId = organizationId;
    this.seriesUID = seriesUID;
    this.resultUID = resultUID;

    await this.initScan();

    this.initialized = true;
    this.setInitializing(false);
    console.log('BmdService initialized');
  }

  //#region Api calls

  /**
   * Initialize result info
   * - Get result info from the backend
   */
  async initScan(): Promise<void> {
    try {
      this.setApiLoading(true);
      this.scanDetail = undefined;

      const response = await this.bmdApiService.getScanDetails({
        organizationId: this.organizationId,
        seriesUID: this.seriesUID,
      });
      this.scanDetailChanged(response);
      console.log('Scan initialized', { scan: response });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setApiLoading(false);
    }
  }

  async generateRoiProjection(): Promise<void> {
    try {
      this.setApiLoading(true);

      const rotationInfo = this.rotationStack;
      const response = await this.bmdApiService.generateRoiProjection({
        organizationId: this.organizationId,
        seriesUID: this.seriesUID,
        resultUID: this.resultUID,
        rotationInfo,
      });
      this.scanDetailChanged(response);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setApiLoading(false);
    }
  }

  //#endregion

  private scanDetailChanged(scanDetail: ScanDetail) {
    this.scanDetail = scanDetail;
    this.scanDetailUpdated?.(scanDetail);
  }

  private handleError(error: unknown) {
    console.error(error);

    if (error instanceof Error) {
      this.showError?.(error.message);
    }

    console.error(error, {
      organzationId: this.organizationId,
      seriesUID: this.seriesUID,
      resultUID: this.resultUID,
    });
  }
}
